import { BLIPP_FEE_PERCENT } from './globalConstants';

export const faq = {
  title: 'Undrar du över något annat?',
  questions: [
    {
      title: 'Billån med säkerhet',
      questions: [
        {
          question: 'Vad är ett billån med säkerhet?',
          answer:
            'Ett billån med säkerhet har bilen som säkerhet vilket innebär en generösare kreditprövning för dig. Samma som du får hos en bilhandlare. Du kan även välja att få ett restvärde på ditt lån för att sänka din månadskostnad. Restvärde kan du läsa mer om nedan.',
        },
        {
          question: 'Vad är det för skillnad på ett billån med säkerhet och ett blancolån?',
          answer:
            'Vårt billån med säkerhet har samma ränta för alla och ger möjlighet till ränteavdrag. Ränta på ett blancolån styrs av din kreditvärdighet, och det saknar möjlighet till ränteavdrag från och med 2026.',
        },
        {
          question: 'Hur mycket sparar jag med ett billån med säkerhet jämfört med blancolån?',
          answer: `Allt styrs av räntan. Räntan på billån är ${process.env.NEXT_PUBLIC_LOAN_INTEREST?.replace(
            '.',
            ','
          )}%, blancolån individuell (mellan 2,95%-29,95%) och bedöms av din kreditvärdighet. Det går inte att ge mer än ett exemplifierande svar på detta.`,
        },
        {
          question: 'Vad är det för ränta på billånet?',
          answer: `Räntan är för tillfället ${process.env.NEXT_PUBLIC_LOAN_INTEREST?.replace(
            '.',
            ','
          )}% på avbetalning för privatpersoner.`,
        },
        {
          question: 'Måste jag betala en kontantinsats?',
          answer:
            'Ja, eftersom vi erbjuder billån med säkerhet så är det lagkrav på att lägga minst 20% av bilens köpesumma i kontantinsats. Kontantinsatsen betalar du till blipp med Swish vid leverans av bilen.',
        },
        {
          question: 'Vad är restvärde?',
          answer:
            'Restvärde är det du har kvar att betala på bilen till finansbolaget när din avbetalningsperiod är över. Du kan alltid välja att förlänga ditt lån efter din avbetalningsperiod.',
        },
        {
          question: 'Vad är amortering?',
          answer: 'Amortering är avbetalningen du gör på din skuld till finansbolaget.',
        },
        {
          question: 'Kan jag ha autogiro på lånet?',
          answer:
            'Ja det kan du. När du har ett privatlån eller lån med säkerhet kan du ansluta till autogiro. Detta gör du via din internetbank.',
        },
      ],
    },
    {
      title: 'Ansöka och betala billån',
      questions: [
        {
          question: 'Kan jag betala in extra på mitt lån när som helst?',
          answer:
            'Ja. En extra betalning minskar skulden, vilket betyder att din månadskostnad kommer att sjunka, men avbetalningstiden blir densamma.',
        },
        {
          question: 'Kan jag lösa mitt lån när jag vill?',
          answer: 'Ja du kan när som helst lösa ditt lån utan extra avgifter.',
        },
        {
          question: 'Är det några övriga avgifter för lånet?',
          answer:
            'Ja. När du startar ditt kontrakt så betalar du 695 kr i uppläggingsavgift till Nordea. Fakturaavgift på 55kr kommer på din månadsfaktura. Du kan välja autogiro eller E-faktura och få ner din fakturaavgift till 45kr / mån.',
        },
        {
          question: 'Gör ni en kreditprövning när jag ansöker om ett lån?',
          answer:
            'Ja. Vi skickar in din ansökan till Nordea som gör en kreditprövning. Din kreditprövning är giltig i sex månader så vill du utöka ditt lånelöfte under denna tid så görs det inte någon mer kreditprövning, sålänge det utökade beloppet inte överskrider 10% av det ursprungliga beloppet.',
        },
        {
          question: 'Hur länge är kreditprövningen giltig?',
          answer: 'Din kreditprövning är giltig i sex månader.',
        },
        {
          question: 'Vad är en medsökande?',
          answer:
            'Medsökande kallas även medlåntagare. Det är en familjemedlem som står med på lånet för att stärka upp inkomstbilden. Tex sambo / fru, föräldrar eller barn.',
        },
        {
          question: 'Kan jag få ett lån fast jag har betalningsanmärkningar?',
          answer:
            'Kanske, Nordea gör en kreditprövning och har du en stark kreditvärdighet trots en betalningsanmärkning så kan det kanske gå.',
        },
        {
          question: 'Vad är kraven för att få låna?',
          answer: 'Du måste vara minst 18 år, ha en fast inkomst och skriven i Sverige.',
        },
        {
          question: 'Hur mycket kan jag låna?',
          answer:
            'Storleken på ditt lån bestäms utifrån din återbetalningsförmåga så det går ej att säga i förväg. Du behöver göra en låneansökan. Detta gör du enklast under "Låna till fordon".',
        },
      ],
    },
    {
      title: 'Bilen',
      questions: [
        {
          question: 'Vilken bil kan jag köpa med blipp?',
          answer:
            'I tjänsten kan du med ett lån från Nordea köpa en bil som ägs av en privatperson eller företag som, med några få undantag. Vill du köpa en bil som företag så kan du göra det genom att skicka in din ansökan på blipp för företag så hjälper vi dig.',
        },
        {
          question: 'Vilken bil kan jag sälja med blipp?',
          answer:
            'I tjänsten kan du som privatperson och företagare sälja en bil som är max 12 år gammal och har gått max 18 000 mil, med några få undantag.',
        },
        {
          question: 'Hur vet jag att bilen är i bra skick?',
          answer:
            'När du hittat en bil som du vill köpa registrerar du den på blipp och då genomför vi en digital kontroll av bilen. När du väl ska titta på bilen så är blipp med dig via ett samtal och erbjuder dig hjäpen att gå igenom bilen tillsammans med dig. Är du nöjd med skicket på bilen och den uppfyller våra krav så kan du att köpa den med blipp. Du har även möjlighet att köpa till ett års garanti om bilen uppfyller villkoren för garanti.',
        },
        {
          question: 'Vad händer om bilen går sönder?',
          answer:
            'Om du köpt till en garanti kontaktar du blipp och vi mailar information gällande skaderutin som du ska ha med dig till verkstaden som du själv kontaktar. Du betalar ingen självrisk på reparationen. Har du inte köpt till en garanti kan du kontakta oss på hej@blipp.se',
        },
        {
          question: 'Vad händer om jag krockar bilen?',
          answer: 'Då kontaktar du ditt försäkringsbolag där du har din bilförsäkring.',
        },
        {
          question: 'Hur vet jag att bilen är svensksåld eller importerad?',
          answer: 'Detta ser du när du gör en digital kontroll av bilen under "Köp fordon".',
        },
        {
          question: 'Hur vet jag att mätarställningen stämmer?',
          answer:
            'Blipp kontrollerar mätarställningen mot car.info som jämför mätarställningen från tidigare besiktningar och servicetillfällen.',
        },
        {
          question: 'Kan jag köpa en bil med körförbud?',
          answer: 'Nej, det kan du inte.',
        },
        {
          question: 'Kan jag sälja en kompis bil?',
          answer:
            'Nej. Det är bara registrerad ägare som kan sälja sin egen bil. Men du kan agera ombud åt en kompis. ',
        },
        {
          question: 'Jag ska köpa en bil som har ett lån, kan jag köpa den med Blipp?',
          answer:
            'Ja det kan du. Vid köptillfället så löser blipp bort det gamla lånet och lägger upp ditt nya lån. Tryggt och säkert.',
        },
        {
          question: 'Min bil som jag ska sälja har ett lån, hur gör man då?',
          answer:
            'Vid leveranstillfället så betalar blipp av ditt lån och betalar ut överskottet till ditt konto med en direktöverföring. Du ser pengarna direkt.',
        },
        {
          question: 'Min bil ägs av mitt företag, kan jag sälja den med Blipp?',
          answer: 'Ja, du skapar ett konto och registrerar bilen till försäljning.',
        },
        {
          question:
            'Jag vill köpa en bil som ägs av ett företag eller bilhandlare, kan jag köpa den med Blipp?',
          answer: 'Ja.',
        },
      ],
    },
    {
      title: 'Garantin',
      questions: [
        {
          question: 'Hur lång är garantin?',
          answer:
            'Du kan köpa till en garanti på 12, 24 eller 36 månader. Köper du en bil med vårt lån kan du baka in kostnaden för garantin i lånet.',
        },
        {
          question: 'Vem utfärdar garantin?',
          answer: 'AutoConcept utfärdar garantin och du får ett garantibevis vid köptillfället.',
        },
        {
          question: 'Får jag ett garantibevis?',
          answer: 'Ja. Du får garantibeviset vid köptillfället på mailen i samband med ägarbytet.',
        },
        {
          question: 'Vad är det för självrisk på garantin?',
          answer: 'Det är ingen självrisk på garantin.',
        },
        {
          question: 'Vart kan jag laga bilen?',
          answer:
            'Du får laga bilen på auktoriserad verkstad som godkänns av AutoConcept. Viktigt att ha skadan godkänd INNAN reparation påbörjas.',
        },
        {
          question: 'Vad kostar garantin?',
          answer: 'Garantin kostar 3 995 kr.',
        },
        {
          question: 'Hur gör jag om bilen går sönder?',
          answer:
            'Du kontaktar blipp som hjälper dig med en skaderutin som du ska ha med dig till verkstaden vid reparation.',
        },
        {
          question: 'Vilka bilar kan du ej köpa till garanti för?',
          answer:
            'Bilar och MC som är äldre än 20 år samt husbil/husvagn som är äldre än 15 år, kan vi ej erbjuda garanti till. Det gäller även bilar som är optimerade.',
        },
      ],
    },
    {
      title: 'Försäkringen',
      questions: [
        {
          question: 'Kan jag teckna en försäkring via er?',
          answer:
            'I ditt köp ingår en 14-dagars helförsäkring från Trygg-Hansa, vilket säkerställer att du har en försäkring när du lämnar säljaren. Efter denna period har du möjlighet att välja en egen försäkring. Det är av yttersta vikt att du har en giltig försäkring efter de första 14 dagarna. Om försäkringen inte är i kraft, är bilen oförsäkrad, vilket innebär en risk och kan medföra betydande kostnader från Trafiksäkringsföreningen.',
        },
        {
          question: 'Hur vet jag priset på försäkringen?',
          answer:
            'Om du väljer en försäkring från Trygg-Hansa kommer du att få ett erbjudande skickat till dig inom cirka en vecka efter ditt köp, där årspremien framgår. Det är helt upp till dig om du vill fortsätta med försäkringen. När du jämför premier är det viktigt att du jämför försäkringar av likvärdig karaktär och säkerställer att du har ett skydd som passar just dina behov. Nordea ställer krav på att du har en försäkring som täcker det objekt som utgör säkerhet för ditt lån.',
        },
      ],
    },
    {
      title: 'Genomgången',
      questions: [
        {
          question: 'Hur lång tid tar genomgången?',
          answer:
            'Beroende på hur väl förberedda du och din säljare/köpare är men cirka 30-60 minuter.',
        },
      ],
    },
    {
      title: 'Vad kostar Blipp?',
      questions: [
        {
          question: 'Vad kostar blipp och vem betalar?',
          answer: `Blipp kostar ${BLIPP_FEE_PERCENT} % av det slutgiltiga priset på fordonet för köparen om du bara vill ha hjälp med själva bilaffären.`,
        },
      ],
    },
    {
      title: 'Vanliga frågor',
      questions: [
        {
          question: 'Vad behöver jag ha med mig när jag ska köpa en bil?',
          answer:
            'Innan ditt bilköp ska äga rum behöver du skapa ett konto på blipp och du behöver även höja din swishgräns i din internetbank så du kan betala din kontantinsats. Kan du inte betala din kontantinsats så kan du ej genomföra ditt köp. Väl på plats vid affären behöver du ha med dig en laddad telefon med fungerande bankID.',
        },
        {
          question: 'Vad behöver jag ha med mig när jag ska sälja en bil?',
          answer:
            'Innan försäljningen behöver du skapa ett konto på blipp och om du har en kredit på bilen behöver du skicka in en såkallad lösenoffert av krediten till blipp. Väl på plats vid affären behöver du ha med dig en laddad telefon med fungerande bankID och det senast utfärdade reg. beviset. ',
        },
        {
          question: 'Vad behövs för att ägarbyta?',
          answer:
            'Säljarens senast utfärdade registreringsbevis. Om ni inte har det tillhanda kan vi ändå hjälpa er med affären. Men själva registreringen hos Transportstyrelsen dröjer då ett par dagar.',
        },
        {
          question: 'Vad är ett regbevis?',
          answer:
            'Det är en värdehandling från Transportstyrelsen Kallas även den “gula lappen” av vissa.',
        },
        {
          question: 'Vad är kontrollnummer?',
          answer:
            'Kontrollnummer är en tiosiffrig kod som finns på ditt gula registreringsbevis. Börjar med en tvåa.',
        },
        {
          question: 'Vad är utfärdandedatum?',
          answer:
            'När Transportstyrelsen utfärdade ditt registreringsbevis. Det senaste datumet är det som är giltigt.',
        },
        {
          question: 'Hur snabbt genomförs ägarbytet?',
          answer:
            'Ditt ägarbyte, garanti och försäkring börjar gälla omgående när du får ut din bekräftelse från oss, men i bilregistret på Transportstyrelsens hemsida uppdateras nya ägare varje vardag kl 19:30.',
        },
        {
          question: 'Behöver jag skriva på något?',
          answer:
            'Ja. Kontrakt signeras för finanskontrakt eller köpekontrakt och säljkontrakt. Det görs digitalt direkt i mobil eller dator med Mobilt BankID.',
        },
        {
          question: 'När får jag betalt för min bil?',
          answer:
            'Blipp samarbetar med Swish och Trustly. Din utbetalning sker tryggt och säkert oavsett vilken bank du har. I de fall vi hjälper dig att lösa ditt gamla billån så kan det dröja några dagar innan pengarna når dig då processtiderna skiljer mellan olika finansbolag.',
        },
      ],
    },
  ],
};
export const faqCompanyLeasing = {
  title: 'Vanliga frågor om företagsleasing',
  questions: [
    {
      title: 'Vad är företagsleasing av bil?',
      questions: [
        {
          question: '',
          answer:
            'Företagsleasing är en typ av avtal där ett företag betalar en månatlig avgift för att använda en eller flera bilar istället för att köpa dem direkt. Företagsleasing av bil har många fördelar när du behöver en bil till företaget eller som förmånsbil. Bilfinansieringen innebär att du inte behöver binda kapital i bilen som vid ett vanligt bilköp och vet vad bilen kommer att kosta varje månad. Vi på Blipp kan hjälpa dig hitta en skräddarsydd lösning där du får hjälp av våra professionella rådgivare – hela vägen',
        },
      ],
    },
    {
      title: 'Företagsbil eller förmånsbil?',
      questions: [
        {
          question: '',
          answer:
            'När du driver ett företag är det ofta viktigt att maximera avkastningen på kapitalet och att inte belasta företagets likviditet i onödan. En lösning som blivit allt populärare är att skaffa företagsbil eller förmånsbil via företagsleasing. Det innebär att bilen används som säkerhet och att du får en leasingavgift varje månad som följer bilens värdeminskning. Fördelarna är flera oavsett om du ska företagsleasa arbetsbil eller förmånsbil, ibland kallat tjänstebil.',
        },
      ],
    },
    {
      title: 'Finansiell leasing eller operationell leasing?',
      questions: [
        {
          question: '',
          answer:
            'Två former som är vanliga vid billeasing är finansiell leasing och operationell leasing. Finansiell leasing liknar ett billån men medför bland annat bokföringsfördelar då halva eller hela momsen beroende på biltyp blir avdragsgill. Samt att man vid avyttring tar del av eventuellt överskott. Operationell leasing kan likställas med en bilhyra. Du betalar den avtalade månadskostnaden, kör bilen det överenskomna miltalet per år, och vid löptidens slut lämnar du tillbaka bilen till bilfirman eller finansbolaget. Du tar då inte del av eventuell överskott. Genom Blipp kan vi erbjuda finansiell leasing.',
        },
      ],
    },
    {
      title: 'Företagsleasing för nytt eller begagnat?',
      questions: [
        {
          question: '',
          answer:
            'Vilket som passar bäst för ditt företag och ert bilbehov beror på hur bilen ska användas och hur er situation ser ut i övrigt. Våra professionella rådgivare på Blipp kan hjälpa er med skräddarsydda upplägg av företagsleasing för begagnade bilar.',
        },
      ],
    },
    {
      title: 'Dra av momsen på bil?',
      questions: [
        {
          question: '',
          answer:
            'När du företagsleasar en förmånsbil kan leasingkostnaden bokföras som en rörelsekostnad och halva momsen är avdragsgill. Vissa kalkyler visar att månadsbetalningen kan bli 15-20 procent lägre för ett företag som använder företagsleasing jämfört med att köpa bilen kontant eller via checkkrediten',
        },
      ],
    },
    {
      title: 'Leasing för bland annat Volvo, Volkswagen, Audi, Tesla eller annan bil?',
      questions: [
        {
          question: '',
          answer:
            'Oavsett om du söker företagsleasing eller business lease av en Volvo, Volkswagen, Audi, Tesla eller annan bil kan vi på Blipp hjälpa dig att hitta en skräddarsydd lösning där du får hjälp av våra professionella rådgivare – hela vägen. Det som kan påverka din månadskostnad är bland annat körsträcka, ränta, bostadsort och om service och försäkring ska ingå i leasingfakturan.',
        },
      ],
    },
    {
      title: 'Leasing av elbil eller traditionell bil?',
      questions: [
        {
          question: '',
          answer:
            'I dag är det också viktigare att titta på bilens totala ägarkostnader än att endast stirra sig blind på inköpspriset för bilen. Elektriska bilar ger både ett lägre förmånsvärde för brukaren och lägre sociala avgifter för företaget. Dessutom är drivmedlet betydligt billigare.',
        },
      ],
    },
  ],
};

export const faqCompany = {
  title: 'Vanliga frågor om företagsaffären',
  questions: [
    {
      title: 'Vad är företagsleasing av bil?',
      questions: [
        {
          question: '',
          answer:
            'Företagsleasing är en typ av avtal där ett företag betalar en månatlig avgift för att använda en eller flera bilar istället för att köpa dem direkt. Företagsleasing av bil har många fördelar när du behöver en bil till företaget eller som förmånsbil. Bilfinansieringen innebär att du inte behöver binda kapital i bilen som vid ett vanligt bilköp och vet vad bilen kommer att kosta varje månad. Vi på Blipp kan hjälpa dig hitta en skräddarsydd lösning där du får hjälp av våra professionella rådgivare – hela vägen',
        },
      ],
    },
    {
      title: 'Företagsbil eller förmånsbil?',
      questions: [
        {
          question: '',
          answer:
            'När du driver ett företag är det ofta viktigt att maximera avkastningen på kapitalet och att inte belasta företagets likviditet i onödan. En lösning som blivit allt populärare är att skaffa företagsbil eller förmånsbil via företagsleasing. Det innebär att bilen används som säkerhet och att du får en leasingavgift varje månad som följer bilens värdeminskning. Fördelarna är flera oavsett om du ska företagsleasa arbetsbil eller förmånsbil, ibland kallat tjänstebil.',
        },
      ],
    },
    {
      title: 'Finansiell leasing eller operationell leasing?',
      questions: [
        {
          question: '',
          answer:
            'Två former som är vanliga vid billeasing är finansiell leasing och operationell leasing. Finansiell leasing liknar ett billån men medför bland annat bokföringsfördelar då halva eller hela momsen beroende på biltyp blir avdragsgill. Samt att man vid avyttring tar del av eventuellt överskott. Operationell leasing kan likställas med en bilhyra. Du betalar den avtalade månadskostnaden, kör bilen det överenskomna miltalet per år, och vid löptidens slut lämnar du tillbaka bilen till bilfirman eller finansbolaget. Du tar då inte del av eventuell överskott. Genom Blipp kan vi erbjuda finansiell leasing.',
        },
      ],
    },
    {
      title: 'Företagsleasing för nytt eller begagnat?',
      questions: [
        {
          question: '',
          answer:
            'Vilket som passar bäst för ditt företag och ert bilbehov beror på hur bilen ska användas och hur er situation ser ut i övrigt. Våra professionella rådgivare på Blipp kan hjälpa er med skräddarsydda upplägg av företagsleasing för begagnade bilar.',
        },
      ],
    },
    {
      title: 'Dra av momsen på bil?',
      questions: [
        {
          question: '',
          answer:
            'När du företagsleasar en förmånsbil kan leasingkostnaden bokföras som en rörelsekostnad och halva momsen är avdragsgill. Vissa kalkyler visar att månadsbetalningen kan bli 15-20 procent lägre för ett företag som använder företagsleasing jämfört med att köpa bilen kontant eller via checkkrediten',
        },
      ],
    },
    {
      title: 'Leasing för bland annat Volvo, Volkswagen, Audi, Tesla eller annan bil?',
      questions: [
        {
          question: '',
          answer:
            'Oavsett om du söker företagsleasing eller business lease av en Volvo, Volkswagen, Audi, Tesla eller annan bil kan vi på Blipp hjälpa dig att hitta en skräddarsydd lösning där du får hjälp av våra professionella rådgivare – hela vägen. Det som kan påverka din månadskostnad är bland annat körsträcka, ränta, bostadsort och om service och försäkring ska ingå i leasingfakturan.',
        },
      ],
    },
    {
      title: 'Leasing av elbil eller traditionell bil?',
      questions: [
        {
          question: '',
          answer:
            'I dag är det också viktigare att titta på bilens totala ägarkostnader än att endast stirra sig blind på inköpspriset för bilen. Elektriska bilar ger både ett lägre förmånsvärde för brukaren och lägre sociala avgifter för företaget. Dessutom är drivmedlet betydligt billigare.',
        },
      ],
    },
  ],
};

export const faqBuyBlocketCar = {
  title: '10 tips när du ska köpa bil på Blocket',
  questions: [
    {
      title: 'Bestäm vad du letar efter',
      questions: [
        {
          question: '',
          answer:
            'Visst kan det vara kul att leta bilar lite planlöst på Blocket, men det kan sluta med ett dyrbart impulsköp av en bil som inte passar dina behov. Lista ner vilka krav du har på din bil och försök hitta märken och bilmodeller som stämmer in på dina kriterier.',
        },
      ],
    },
    {
      title: 'Snäva in din sökning',
      questions: [
        {
          question: '',
          answer:
            'Om du har hittat en bilmodell som du tror passar dina behov, till exempel Volvo XC60, kan det vara en god idé att snäva in dina sökningar ytterligare. Kanske vet du vilken motorvariant som passar dig bäst, hur många mil bilen bör ha gått och vilka årsmodeller som är mest intressanta. Med hjälp av sökverktygen kan du hitta de perfekta bilarna. Du kan också sortera på billigast, dyrast och nyast och du kan filtrera mellan privata och handlarbilar.',
        },
      ],
    },
    {
      title: 'Lägg in bevakningar',
      questions: [
        {
          question: '',
          answer:
            'Förr eller senare dyker den perfekta bilen upp. Rätt färg, välvårdad, men en ägare och till rätt pris. För att du snabbt ska hinna kontakta säljaren kan det vara bra att lägga in en bevakning. Då får du meddelande från Blocket när objekten du tittar efter dyker upp.',
        },
      ],
    },
    {
      title: 'Läs annonsen noga',
      questions: [
        {
          question: '',
          answer:
            'Du har säkert själv varit med om att sälja något och få frågor om sådant som redan står i annonstexten. Läs annonsen noga och försök lista ner objektets starka och svaga sidor. Fundera på vad du mer vill veta innan du kontaktar säljaren.',
        },
      ],
    },
    {
      title: 'Gör en bakgrundskontroll',
      questions: [
        {
          question: '',
          answer:
            'Innan du går vidare bör du göra en bakgrundskontroll och ta fram så många biluppgifter som möjligt. Hos Blipp kan du kolla upp så att bilen inte är mätarskruvad, har en godkänd besiktning och att allt i övrigt ser ut att vara i ordning.',
        },
      ],
    },
    {
      title: 'Räkna ut månadskostnaden',
      questions: [
        {
          question: '',
          answer:
            'När du vet den exakta köpesumman kan du räkna ut vad billånet kostar per månad i Blipps räknesnurra. Tänk på att räkna in andra ägarkostnader som skatt, bränsle, försäkring, däck och service i din ägarkalkyl.',
        },
      ],
    },
    {
      title: 'Använd Blipp',
      questions: [
        {
          question: '',
          answer:
            'Ta hjälp av Blipp när du köper en begagnad bil privat. Vi kan ordna ett lånelöfte på ett billån innan du ska köpa bilen. Du kan även göra bakgrundskontroller av bilar hos Blipp. När det är dags för affär så legitimerar sig både köpare och säljare med bank-id hos på Blipp. Blipp ser till att eventuella restskulder på billånet löses. Vi ordnar så att säljaren får betalt och vi tar fram avtal som signeras med bank-id. Dessutom ingår försäkring i två veckor och du kan köpa till ett års garanti. Du slipper allt som är krångligt och svårt med den privata bilaffären.',
        },
      ],
    },
    {
      title: 'Se upp för bedrägerier',
      questions: [
        {
          question: '',
          answer:
            'Det förekommer att bedragare annonserar ut bilar på Blocket. Känns något för bra för att vara sant eller någon detalj för dig misstänksam, avstå, det kommer fler objekt. Om du går via Blipp görs affären med bank-ID och vi kontrollerar så att allt är korrekt.',
        },
      ],
    },
    {
      title: 'Boka tid',
      questions: [
        {
          question: '',
          answer:
            'När allt är bra och du har kollat upp bilen, boka en visning med intention att köpa bilen. Kom överens med säljaren om priset, via Blipp kan ni skicka bud och legitimera er med bank-id. Kontakta Blipp för att göra upp affären så hjälper vi er med all administration i bilaffären.',
        },
      ],
    },
    {
      title: 'Ha alla papper i ordning',
      questions: [
        {
          question: '',
          answer:
            'Glöm inte att ha blanketter för ägarbyte och annan dokumentation som tillhör bilen i ordning. Blipp kan hjälpa er med ägarbytet och alla administration, men vi behöver koden från det gula registreringsbeviset för ägarbytet.',
        },
      ],
    },
  ],
};

export const faqRelease = {
  title: 'Frågor och svar',
  questions: [
    {
      title: 'Varför lägga om företagsleasing?',
      questions: [
        {
          question: '',
          answer:
            'Villkoren för att förlänga leasingavtal är ofta oflexibla vilket enligt vår erfarenhet många företag lider av. Blipp kan hjälpa ditt företag att lägga om leasingavtal enkelt och flexibelt och utgår ifrån det som passar ditt företag bäst. Re:lease har blivit ett attraktivt alternativ för många företagare givet långa leveranstider på nya bilar.',
        },
      ],
    },

    {
      title: 'Kan jag verkligen bryta min företagsleasing i förtid?',
      questions: [
        {
          question: '',
          answer:
            'Ja det kan du. Restskulden som vi får från ditt nuvarande finansbolag inkluderar eventuella avgifter som uppstår när man bryter leasingen. Dock rekommenderar vi inte att bryta kontrakt som har löpt under 18 månader på grund av att avgifterna för uppsägning av finansiella leasingkontrakt kan vara högre för yngre avtal.',
        },
      ],
    },
    {
      title: 'Kan jag samla alla mina leasingbilar?',
      questions: [
        {
          question: '',
          answer:
            'Varje kalkyl och kund är individuell men i många fall går det att samla bilarna på ett och samma finansbolag och även få en samlingsfaktura på alla objekt.',
        },
      ],
    },
    {
      title: 'Vad händer med min försäkring när jag lägger om leasingen?',
      questions: [
        {
          question: '',
          answer:
            'När leasingen läggs om kommer du få en 14 dagars kostnadsfri helförsäkring från Trygg Hansa, följt av 15% rabatt på försäkringspremien första året om du väljer att fortsätta.',
        },
      ],
    },

    {
      title: 'Vad händer om jag bryter min leasing mitt i månaden?',
      questions: [
        {
          question: '',
          answer:
            'Exempel: Du skriver på avtal den 9:e november. Då kommer du få första fakturan med en initalhyra för de dagarna som är kvar i November (21 dagar) och sedan för hela nästkommande månad (December). Vill du istället bryta leasingavtalet i slutet av månaden, prata med din rådgivare så fixar de det.',
        },
      ],
    },
    {
      title: 'Finansiell leasing eller operationell leasing?',
      questions: [
        {
          question: '',
          answer:
            'Två former som är vanliga vid billeasing är finansiell leasing och operationell leasing. Finansiell leasing liknar ett billån men medför bland annat bokföringsfördelar då halva eller hela momsen beroende på biltyp blir avdragsgill. Samt att man vid avyttring tar del av eventuellt överskott. Operationell leasing kan likställas med en bilhyra. Du betalar den avtalade månadskostnaden, kör bilen det överenskomna miltalet per år, och vid löptidens slut lämnar du tillbaka bilen till bilfirman eller finansbolaget. Du tar då inte del av eventuell överskott. Genom Blipp kan vi erbjuda finansiell leasing.',
        },
      ],
    },
  ],
};
